<template>
  <div class="playOrderFormContainer">
    <span class="playOrderFormTitle"> Play Order Generator </span>
    <p>
      This Play Order Generator is designed to assist with the devious task of
      selecting the playing order for TenUp. This Generator is the perfect way
      to decide the faith of you and your friends.
    </p>
    <p v-if="playOrderNotGenerated">
      To use this Generator, type up to five player names below. Unless you are
      using Random Mode, you should enter the player names in the current
      seating order.
    </p>
    <p v-else>
      The Generator has decided! Are you ready to shuffle, {{ playOrder[0] }}?
    </p>
    <form
      v-if="playOrderNotGenerated"
      id="playOrderForm"
      @submit="playOrderFormSubmit"
      method="POST"
    >
      <div>
        <span>Amount of players: </span>
        <select v-model="playersAmount">
          <option v-bind:value="2">2</option>
          <option v-bind:value="3">3</option>
          <option v-bind:value="4">4</option>
          <option v-bind:value="5">5</option>
        </select>
      </div>
      <div>
        <input
          v-model="player1"
          type="text"
          name="player1"
          placeholder="Player 1"
        />
      </div>
      <div>
        <input
          v-model="player2"
          type="text"
          name="player2"
          placeholder="Player 2"
        />
      </div>
      <div v-if="playersAmount >= 3">
        <input
          v-model="player3"
          type="text"
          name="player3"
          placeholder="Player 3"
        />
      </div>
      <div v-if="playersAmount >= 4">
        <input
          v-model="player4"
          type="text"
          name="player4"
          placeholder="Player 4"
        />
      </div>
      <div v-if="playersAmount >= 5">
        <input
          v-model="player5"
          type="text"
          name="player5"
          placeholder="Player 5"
        />
      </div>
      <div>
        <label for="randomMode">Random Mode</label>
        <input
          id="randomMode"
          v-model="randomMode"
          type="checkbox"
          name="randomMode"
        />
        <p class="warning" v-if="randomMode">
          Warning! Enabling Random Mode may inevitably lead to the necessity of
          changing seats. If you are already seated and you are a true autist,
          you should disable Random Mode.
        </p>
      </div>
      <div>
        <input type="submit" value="Submit" />
      </div>
    </form>
    <div v-else>
      The playing order is as follows:
      <p
        class="playingOrder"
        v-for="(player, index) in playOrder"
        v-bind:key="index"
      >
        {{ player }}
      </p>
      <button @click="resetGenerator">Reset</button>
    </div>
  </div>
</template>

<script>
function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

module.exports = {
  data: function () {
    return {
      playersAmount: 4,
      player1: "",
      player2: "",
      player3: "",
      player4: "",
      player5: "",
      randomMode: false,
      playOrderNotGenerated: true,
      playOrder: [],
    };
  },
  methods: {
    playOrderFormSubmit(e) {
      e.preventDefault();

      var players = [
        this.player1,
        this.player2,
        this.player3,
        this.player4,
        this.player5,
      ];

      var spliceAmount = 5 - this.playersAmount;

      if (spliceAmount > 0) {
        let spliceStart = this.playersAmount;
        players.splice(spliceStart, spliceAmount);
      }

      if (this.randomMode) {
        this.playOrder = shuffle(players);
      } else {
        let startingPlayerIndex = randomInteger(0, this.playersAmount - 1);
        let removedPlayers = players.splice(0, startingPlayerIndex);
        this.playOrder = players.concat(removedPlayers);
      }

      this.playOrderNotGenerated = false;
    },
    resetGenerator() {
      this.playOrderNotGenerated = true;
      this.playOrder = [];
    },
  },
};
</script>

<style scoped>
div {
  margin: 10px;
}

p {
  max-width: 400px;
  margin: 10px auto;
}

span.playOrderFormTitle {
  font-size: 2em;
  font-weight: bolder;
}

p.playingOrder {
  font-weight: bolder;
}

.warning {
  background-color: red;
  padding: 1em;
  border-color: 3px solid darkred;
  border-radius: 5px;
}
</style>>
